<template>
	<div class="loginPage">
		<!-- <nav-bar :nav-info="navInfo"></nav-bar> -->
		<div class="login">
			<div class="view_margin"><img class="login_logo" src="../../assets/img/icon/logo.png" /></div>
			<div class="login_form">
				<input v-model="userName" type="text" placeholder="请输入账号" class="qxs-ic_user qxs-icon"/>
				<input class="qxs-ic_password qxs-icon" type="password" placeholder="请输入密码" :password="showPassword" v-model="passWord" show-password/>
				<div style="margin-top: 1.55rem;">
					<van-button class="btn" block  native-type="submit" @click="login">登录</van-button>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import NavBar from '../../components/NavBar/NavBar.vue'
import { Toast } from 'vant';
export default {
	components: { NavBar },
	data() {
		return {
			userName: '',
			passWord: '',
			isBtnLoading: false,
			showPassword: true,
			 //navbar信息
      // navInfo:{
      //   title:'登录'
      // },
			userId:''
		};
	},
  created() {
		//  console.log(this.$cookie.get('userName'));
		//记录存在cookie中的用户名和密码，如果存在则去自动登录
		 if (this.$cookie.get('userName') && this.$cookie.get('passWord')) {
			 this.userName = this.$cookie.get('userName')
			 this.passWord = this.$cookie.get('passWord')
			 this.login()
		 }
  },
	methods: {
		//去登录
		 async login() {
			 if (this.userName==''||this.passWord=='') {
				 Toast("请输入账号或密码")
				 return false
			 }
			let params = {
				username:this.userName,
				password:this.passWord
			}
			let res = await this.$Api.userLogin(params);
			if (res.data.code==1) {
				this.$cookie.set('userName',this.userName,7)
				this.$cookie.set('passWord',this.passWord,7)
				this.$store.dispatch('login',res.data)
				this.userId = res.data.userInfo.deptId
				this.isHasPlan()
				Toast("登录成功")
				this.$router.push({
					path:'/home'
				})
			}else {
				Toast("账号或密码错误")
			}
		},
		//验证是否有承运计划
		async  isHasPlan() {
      let params = {
        userId:this.userId
      }
      let res = await this.$Api.isHasPlan(params)
			// this.$cookie.set('isHas',res.data.msg,7)
			//储存到session vuex
			this.$store.dispatch('setIsHas',res.data)

    },
	}
};
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
}
.btn {
  margin: 0 auto;
  width: 7.84rem;
  height: 1.17rem;
  background-color: #ff6600;
  color: #ffffff;
  border-radius: 1rem;
  font-size: 0.37rem;
  letter-spacing: 0.3rem;

}


.loginPage {
  position: absolute;
  top: 0;
  left: 0;
	background-color: #fff;
	height: 100%;
	.login {
		padding: 0 10vw;
	}
}
.qxs-icon {
	font-size: 16px;
	height: 40px;
	width: 68vw;
	margin-bottom: 5px;
	// padding-left: 10%;
	border: 0;
	border-bottom: solid 1px #d2d2d2;
	padding-left: 40px;
}

.login_form {
	padding-top: 10%;
}

.qxs-ic_user {
	background: url('../../assets/img/icon/icon_claim_goods_name.png') no-repeat;
	background-size: 0.64rem;
	background-position: 0%;
}

.qxs-ic_password {
	margin-top: 0.48rem;
	background: url('../../assets/img/icon/icon_forgot_password_password.png') no-repeat;
	background-size: 0.64rem;
	background-position: 0%;
	// margin-bottom: 50px;
}
.view_margin {
	padding-top: 2.4rem;
  margin: 8vw auto;
  width: 5.01rem;
	height: 2.96rem;
	text-align: center;
}
.login_logo {
	width: 100%;
	height: 100%;
	margin: 0 auto;
}
</style>
